<template>
	<div v-if="doctorInfo">
		<el-dialog :title="titleWindows" :visible.sync="openWindows"
		width="950px" append-to-body >
				  <el-descriptions title="转院信息"   >
				      <el-descriptions-item label="预约时间">{{doctorInfo.time}}</el-descriptions-item>
				   
				   <el-descriptions-item label="申请时间">{{doctorInfo.createTime}}</el-descriptions-item>
				   
				   </el-descriptions>
				  <el-descriptions title="接诊医生" v-if="doctorInfo">
				      <el-descriptions-item label="姓名">{{doctorInfo.doctor.name}}</el-descriptions-item>
					
					<el-descriptions-item label="医院">{{doctorInfo.doctorInfo.hospitalName}}</el-descriptions-item>
					
					
				  
				  </el-descriptions>
				  
				  <el-descriptions title="会诊患者"   v-if="doctorInfo.patient">
				      <el-descriptions-item label="姓名" >{{doctorInfo.patient.name}}</el-descriptions-item>
					  
					  <el-descriptions-item label="性别">
						  <template v-if="doctorInfo.patient.sex==0">男</template>
						   <template v-else>女</template>
						  
					  </el-descriptions-item>
					  
					  <el-descriptions-item label="出身日期">{{doctorInfo.patient.birthday}}</el-descriptions-item>
				   </el-descriptions>
				   
				   <el-descriptions title="病情"   >
				        <el-descriptions-item label="描述">{{doctorInfo.caseContent}}</el-descriptions-item>
						<el-descriptions-item label="病例照片" v-if="doctorInfo.imgs.length!=0">
							<div class="demo-image__preview">
							  <el-image 
							    style="width: 100px; height: 100px"
							    :src="'https://wei.kongtangweishi.com'+doctorInfo.imgs.split(';')[0]" 
							    :preview-src-list="getImgList(doctorInfo.imgs)">
							  </el-image>
							</div>
							
						</el-descriptions-item>
				    
				
					
					</el-descriptions>
				  
		
		</el-dialog>
		
	</div>
	
</template>

<script>
	import config from '@/common/config.js'
	
	export default {
		name: "consultmentInfo",
		props: {
		  titleWindows:{type:String,default:'会诊信息'},
		  dataId:{type:String,default:""},
		  isWindows: {type: Boolean,default: false,},
		  
		},
		watch: {
		  isWindows: {
		    handler(val) {
		      this.openWindows=val;
		    },
		    immediate: true,
		  },
		  dataId: {
		    handler(val) {
				if(val&&val.length!=0){
					 this.getInfo();
				}
		     
		    },
		    immediate: true,
		  },
		  openWindows: {
		    handler(val) {
				this.openWindows=val;
				this.$emit("openWindows",val);
		    },
		    immediate: true,
		  },
		  
		},
		data() {
			return {
				
				content:'',
				openWindows:false,
				doctorInfo:null,
			}
		},
		created() {
		 
		},
		methods: {
			
			getInfo(){
				
				var _this=this;
				config.ajaxAw("kongtangweishi/api/transfer/info",
				{transferId:this.dataId},function(data){
					
					_this.doctorInfo=data
					
				});
				
				
			},
			getImgList(listStr){
				var list=listStr.split(';');
				var listArr=[];
				for (var i = 0; i < list.length; i++) {
					listArr.push('https://wei.kongtangweishi.com'+list[i]);
				}
				return listArr;
				
			}
			
			
		}
}
	
</script>

<style>
	.bootom_panel{
		margin: 10px;
		text-align: right;
	}
</style>